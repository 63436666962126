import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from 'styled-components';
import { bool } from 'prop-types';

const Menu = ({ open }) => {
  
  const menu = useStaticQuery(graphql`
    query {
      wordpress {
        menus {
          edges {
            node {
              menuItems {
                edges {
                  node {
                    label
                    url
                    id
                    childItems {
                      edges {
                        node {
                          label
                          url
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        generalSettings {
          url
        }
      }
    }
  `)

  const urlSite = menu.wordpress.generalSettings.url;
  const menuItems = menu.wordpress.menus.edges[0].node.menuItems.edges.map(item => ({
    label: item.node.label,
    url: item.node.url.replace(urlSite,''),
    subMenus: item.node.childItems,
  }))
  const [openSubmenu, setOpenSubmenu] = useState(false);

  return (
    <MenuStyle open={open} openSubmenu={openSubmenu}>
      <h2>Menu</h2>
        <nav>
          <ul>
            <li><Link to='/' activeClassName="active">Home</Link></li>
            <li className="submenu"><a onClick={()=> setOpenSubmenu(!openSubmenu)}>Solutions</a></li>
            <div className="submenu-content">
              <li><Link to='/solutions/custom-development' activeClassName="active">Web development</Link></li>
              <li><Link to='/solutions/agency' activeClassName="active">Agency</Link></li>
            </div>
            <li><Link to='/cases' activeClassName="active">Cases</Link></li>
            <li><Link to='/about' activeClassName="active">About</Link></li>
            <li><Link to='/contact' activeClassName="active">Contact</Link></li>
          </ul>
        </nav>
    </MenuStyle>
  );
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu

const MenuStyle = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: ${({ theme }) => theme.secondaryDark};
  height: 100vh;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: transform 0.3s ease-in-out,opacity 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%) translateY(0)'};
  opacity: ${({ open }) => open ? '1' : '0'};
  display: ${({ open }) => open ? 'flex' : 'none'};


  h2 {
    width: 100%;
    position: absolute;
    top: 50px;
    font-size: 2rem;
    &::after {
      content: "";
      display: block;
      width: 16%;
      height: 5px;
      background-color: ${({ theme }) => theme.primaryLight};
      margin: 0 auto;
      margin-top: 10px;
      border-radius: 10px;
    }
  }

  ul {
    list-style: none;
    margin-left: 0px;
  }

  a {
    font-size: 2rem;
    color: white;
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  .active {
    color: ${({ theme }) => theme.primaryLight};
    &::after {
      content: "";
      display: block;
      width: 5%;
      height: 5px;
      background-color: ${({ theme }) => theme.primaryLight};
      margin: 0 auto;
      border-radius: 10px;
    }
  }

  .submenus {
    a {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
    overflow: hidden;
    transition: max-height 0.6s ease;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  .submenu {
    &: hover {
        cursor: pointer;
    }
  }

  .submenu-title {
    color: ${ ({ openSubmenu, theme }) => openSubmenu ? theme.primaryLight : '' };
    &-active {
        &::after {
            content: "";
            display: block;
            width: 5%;
            height: 5px;
            background-color: ${({ theme }) => theme.primaryLight};
            margin: 0 auto;
            border-radius: 10px;
        }
    }
  }

  .submenu-content {
    display: ${({ openSubmenu }) => openSubmenu ? 'inherit' : 'none'};
    margin-top: ${ ({ openSubmenu }) => openSubmenu ? '0.5rem' : '' };
    margin-bottom: ${ ({ openSubmenu }) => openSubmenu ? '0.5rem' : '' };
    a {
        font-size: 1rem;
    }
  }

`

/*
<>
  {item.subMenus.edges.length > 0
    ? (
      <SubMenu
        item={item}
        urlSite={urlSite}
        slug={item.url}
      />
    ) : (
      <li key={item.url} >
        <Link 
          to={item.url}
          activeClassName="active"
        >
          {item.label}
        </Link>
      </li>
    )
  }
</>
*/

/*
{menuItems.map(item => (
  <>
    <li key={item.url} >
      <Link 
        to={item.url}
        activeClassName="active"
      >
        {item.label}
      </Link>
    </li>
  </>
))}
*/
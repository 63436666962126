import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from 'react-helmet';

import "./layout.css";
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../styles/global';
import { theme } from '../styles/theme';

import Header from './header';
import Footer from './footer';
import SEO from '../components/seo';

import '@fortawesome/fontawesome-svg-core/styles.css';

import { library, config } from '@fortawesome/fontawesome-svg-core';

import {
  fal,
  faTachometerAlt, faBriefcase, faHeart, faChartLine,
  faAward, faLightbulb, faDesktopAlt, faMobile,
  faHandshake, faClipboardListCheck, faPencilAlt, faCode, faListAlt, faRocketLaunch, faTools, faRoad,
  faBrowser, faBoxFull, faCameraMovie,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  fal, faTachometerAlt, faBriefcase, faHeart, faChartLine,
  faAward, faLightbulb, faDesktopAlt, faMobile,
  faHandshake, faClipboardListCheck, faPencilAlt, faCode, faListAlt, faRocketLaunch, faTools, faRoad,
  faBrowser, faBoxFull, faCameraMovie,
);

config.autoAddCss = false;

const Layout = ({ children, headerType, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title={pageTitle} />
      <ThemeProvider theme={theme}>
      <GlobalStyles />
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Heebo|Montserrat:700&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet" />
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata.title}
          headerType={headerType}
        />
        <main className="entry-page">{children}</main>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

html {
    scroll-behavior: smooth;
}

.entry-page {
    padding-top: 90px;
}

.container-p {
    padding-top: 10%;
    padding-bottom: 10%;
    @media only screen and (max-width: 991px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}
.container-p-15 {
    padding-top: 15%;
    padding-bottom: 15%;
    @media only screen and (max-width: 991px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}
.container-p-5 {
    padding-top: 5%;
    padding-bottom: 5%;
    @media only screen and (max-width: 991px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.pt-10 {
    padding-top: 10%;
}

.color-link {
    color: ${ ({theme}) => theme.primaryLight};
}

P {
    white-space: pre-wrap;
}

.ul-no-class {
    margin-left: 0px;
    list-style: none;
}

@media only screen and (min-width: 992px) {
    .mobile-991 {display: none;}
    .mobile-767 {display: none;}
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
    .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 991px) {
    .desktop-991 {display: none;}
    .desktop-767 {display: none;}
}

@media only screen and (max-width: 1200px) {
    h1 {
        font-size: 2rem;
    }
}

/* Main Section Transition */

.fadein-transition {
    -webkit-animation: 1s ease 0s normal forwards 1 fadeintransition;
    animation: 1s ease 0s normal forwards 1 fadeintransition;
}

@keyframes fadeintransition {
    0% { opacity:0; transform:translateY(500px); }
    100% { opacity:1; transform:translateY(0px; }
}

@-webkit-keyframes fadeintransition {
    0% { opacity:0; transform:translateY(500px); }
    100% { opacity:1; transform:translateY(0px); }
}

/* Static Fade In Animation */

.fadein-show {
    -webkit-animation: 1s ease 0s normal forwards 1 fadeinshow;
    animation: 1s ease 0s normal forwards 1 fadeinshow;
}

@keyframes fadeinshow {
    0% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes fadeinshow {
    0% { opacity:0; }
    100% { opacity:1; }
}

/* Home Fade in */

.home-fade-in { 
  animation: fadeInAnimation ease 1s; 
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 
} 

@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  }
  66% { 
    opacity: 0; 
  } 
  100% { 
      opacity: 1; 
   } 
}

`


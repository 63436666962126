import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import { useOnClickOutside } from '../hooks';
import Burger from '../components/menu/burger'
import Menu from '../components/menu/menu'

import Logo from '../images/logo-iqplus.svg'
import LogoWhite from '../images/logo-iqplus-white.svg'

const Header = ({ headerType }) => {

  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <HeaderStyle headerType={headerType}>
        <div className="logo-wrapper">
          <Link to="/">
            {headerType === 'dark' ?
              <img src={LogoWhite} alt="IQPLUS Logo" />
            :
              <img src={Logo} alt="IQPLUS Logo" />
            }
            <h1>IQPLUS</h1>
          </Link>
        </div>
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} headerType={headerType} />
          <Menu open={open} setOpen={setOpen} />
        </div>
    </HeaderStyle>
  );
}

export default Header;

const HeaderStyle = styled.div`
  position: fixed;
  height: 90px;
  background: ${({ theme, headerType }) => headerType === 'dark' ? theme.secondaryLight : 'white'};
  width: 100%;
  z-index: 100;

  .logo-wrapper {
    position: absolute;
    top: 25px;
    left: 50px;

    @media only screen and (max-width: 767px) {
      left: 15px;
    }

    a {
      color: ${({ theme, headerType }) => headerType === 'dark' ? 'white' : theme.primaryDark};
      text-decoration: none;
    }

    img {
      max-width: 2.1rem;
      display: inline;
      margin-bottom: 0px;
      margin-right: 10px;
    }

    h1 {
      margin: 0;
      display: inline;
      font-size: 1rem;
      font-family: 'Montserrat', sans-serif;
    }

  }
}
`

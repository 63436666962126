import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LogoWhite from '../images/logo-iqplus-white.svg'
import { faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

    const menu = useStaticQuery(graphql`
        query {
            wordpress {
                menus {
                    edges {
                        node {
                            menuItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
                generalSettings {
                    url
                }
            }
        }
    `)

    const urlSite = menu.wordpress.generalSettings.url;
    const menuItems = menu.wordpress.menus.edges[0].node.menuItems.edges.map(item => ({
      label: item.node.label,
      url: item.node.url.replace(urlSite,'')
    }))

    return(
        <footer>
            <FooterStyled>
            <div className="container">
                <div className="row footer-info">
                    <div className="col-12 col-sm footer-company-info">
                        <h4>IQPLUS AS</h4>
                        <p>hei@iqplus.no</p>
                        <p>+47 462 96 532 | +47 459 66 206</p>
                        <p>Nydalveien 15, 0484 Oslo</p>
                        <p>El Salvador 5218, 1414 Buenos Aires</p>
                    </div>
                    <div className="col desktop-991">
                        <div className="footer-menu">
                            <h3>MENU</h3>
                            <ul className="ul-no-class">
                                {menuItems.map(item => (
                                    <li key={item.url} >
                                        <Link 
                                            to={`${item.url === '/solutions/' ? '/#solutions' : item.url}`}
                                            activeClassName="active"
                                        >
                                            {item.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm">
                        <div className="footer-social">
                            <h3>LET'S BE FRIENDS</h3>
                            <ul className="ul-no-class">
                                <li><a href='https://www.facebook.com/iqplusconsulting/' rel="noopener noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </a></li>
                                <li><a href='https://www.instagram.com/iqplusconsulting/' rel="noopener noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a></li>
                                <li><a href='https://www.linkedin.com/company/iqplusconsulting' rel="noopener noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </FooterStyled>
            <CopyrightStyled>
                <div className="container">
                    <div className="row copyright">
                        <div className="col">
                            <div className="logo-wrapper">
                                <img src={LogoWhite} alt="logo iqplus white"/>
                                <h1>IQPLUS</h1>
                            </div>
                            <div className="copyright-text">
                                <p className="copyright-outshifter">© {new Date().getFullYear()} IQPLUS AS. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </CopyrightStyled>
        </footer>
    )
}
export default Footer;

const FooterStyled = styled.div`

background: ${({ theme }) => theme.secondaryDark};
color: white;

.get-in-touch {
    padding-top: 10%;
    padding-bottom: 5%;
    text-align: center;
    svg {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }
    h3 {
        margin-bottom: 0.5rem;
    }
    h4 {
        margin-bottom: 0.5rem;
    }
    p {
        font-size: 0.8rem;
        max-width: 300px;
        margin: 0 auto;
    }
    span {
        color: ${ ({theme}) => theme.primaryLight};
    }
}

.footer-info {
    padding-top: 5%;
    padding-bottom: 5%;
    p {
        font-size: 0.8rem;
        font-weight: 300;
    }
}

.footer-company-info {
    @media only screen and (max-width: 575px) {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        h4 {
            margin-bottom: 1rem;
        }
    }
}

.footer-social {
    text-align: center;
    h3 {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    svg {
        font-size: 1.5rem;
    }
}

.footer-menu {
    text-align: center;
    h3 {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}

ul {
    display: inline-flex;
}

li {
    margin-right: 15px;
}

.active {
    color: ${({ theme }) => theme.primaryLight};
}

a {
    color: white;
    transition: color 0.3s ease;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
}

p {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
    @media only screen and (max-width: 767px) {
        font-size: 0.8rem;
    }
}

`;


const CopyrightStyled = styled.div`

background: ${({ theme }) => theme.secondaryDark};
border-top: 1px solid white;
text-align: center;
padding-top: 2rem;
padding-bottom: 2rem;
.logo-wrapper {
    margin-bottom: 0.8rem;
}
img {
    width: 1.5rem;
    margin-right: 10px;
}
h1 {
    color: white;
    margin: 0;
    display: inline;
    font-size: 1rem;
}
p {
    color: white;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}
@media only screen and (max-width: 767px) {
    p {
        font-size: 0.7rem;
    }
}

`;
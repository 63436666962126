import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';

const Burger = ({ open, setOpen, headerType }) => {
  return (
    <StyledBurger
      open={open}
      onClick={ () => setOpen(!open) }
      headerType={headerType}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;

export const StyledBurger = styled.button`
  position: absolute;
  top: 25px;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${({ open }) => open ? '2rem' : '1.5rem'};
  height: ${({ open }) => open ? '2rem' : '1.5rem'};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 767px) {
    right: 15px;
  }

  div {
    height: 0.2rem;
    background: ${({ theme, open, headerType }) => !open && headerType === 'dark' ? 'white' : !open ? theme.primaryLight : theme.primaryLight};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
      width: ${({ open }) => open ? '2rem' : '1.5rem'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      width: 1rem;
      margin-left: 0.5rem;
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
      width: ${({ open }) => open ? '2rem' : '0.5rem'};
      margin-left: ${({ open }) => open ? '0rem' : '1rem'};
    }
  }
`;